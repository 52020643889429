export default {
	value: [
		{
			field: "id",
			hide: true,
		},
		{
			field: "check",
			headerName: "",
			checkboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			minWidth: 50,
			maxWidth: 50,
			flex: 0,
		},
		{
			field: "name",
			headerName: "Название группы",
			wrapText: true,
			minWidth: 255,
			cellRenderer: "TextLinkRenderer",
			cellRendererParams: {
				source: "group",
			},
			filter: "CustomTextFilter",
			filterParams: {
				filterName: "Поиск по названию",
			},
		},
		{
			field: "usersCount",
			headerName: "Количество участников",
			minWidth: 180,
			flex: 0,
			maxWidth: 180,
			filter: "CustomNumberFromFilter",
			filterParams: {
				filterName: "Количество участников от",
			},
		},
		{
			field: "edit",
			width: 110,
			headerName: "",
			flex: 0,
			cellRenderer: "EditRenderer",
			cellRendererParams: {
				source: "group",
				groupPage: true,
			},
		},
	],
}