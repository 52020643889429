export default {
	value: [
		{
			field: "id",
			hide: true,
		},
		{
			field: "check",
			headerName: "",
			checkboxSelection: true,
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			minWidth: 50,
			maxWidth: 50,
			flex: 0,
		},
		{
			field: "user",
			headerName: "Имя",
			wrapText: true,
			sortable: true,
			minWidth: 255,
			cellRenderer: "TextLinkRenderer",
			cellRendererParams: {
				source: "staff",
				end: "edit",
			},
		},
		{
			field: "status",
			headerName: "Статус",
			width: 100,
			flex: 0,
			cellRenderer: "StatusRenderer",
		},
		{
			field: "passingDate",
			wrapText: true,
			headerName: "Дата прохождения",
			minWidth: 210,
			sortable: true,
		},
		{
			field: "class",
			headerName: "Обучение",
			width: 115,
			flex: 0,
			cellRenderer: "ClassRenderer",
		},
		{
			field: "action",
			headerName: "",
			flex: 0,
			cellRenderer: "ButtonPass",
			cellStyle: {justifyContent: "flex-end"}
		},
	]
}