<template>
  <Popper placement="right" arrow class="products-popper">
    <button class="products-popper-btn display--flex align-item-center">
      {{ freeCount }} <i class="fal fa-angle-down"></i>
    </button>
    <template #content>
      <ul>
        <li class="display--flex justify-content-between popper-info">Доступно<span>{{ freeCount }}</span></li>
        <li class="display--flex justify-content-between popper-info">Выдано<span>{{ issuedCount }}</span></li>
      </ul>
    </template>
  </Popper>
</template>

<script>
import {ref} from "@vue/reactivity";
import Popper from "vue3-popper";
export default {
  components: {Popper},
  setup(props) {
    const freeCount = ref(null)
    const issuedCount = ref(null)
    // eslint-disable-next-line vue/no-setup-props-destructure
    freeCount.value = props.params.value
    // eslint-disable-next-line vue/no-setup-props-destructure
    issuedCount.value = props.params.data.countIssued ? props.params.data.countIssued : 0;

    return {
      freeCount,
      issuedCount,
    }
  }
}
</script>


<style scoped lang="scss">

.popper-info {
  padding: 10px 9px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
}

.products-popper-btn:hover {
  color: var(--hover-color)
}


</style>