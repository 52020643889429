<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <div class="modal__box">
        <div class="close" @click="$emit('close-modal')">
          <img class="close-img" src="@/assets/images/icons/close.svg" alt="">
        </div>
        <p class="modal__title">Выдать доступ:</p>
        <p class="modal__text">Вы отметили <span>{{ checkedList.length + " " + morph(checkedList.length) }}</span>
          . Выберите продукты, к которым вы хотите выдать доступ.</p>
      </div>
      <div class="modal__table" v-if="productOptions">
        <ag-grid-vue
            :columnDefs="columnDefs.value"
            :rowData="rowData"
            :rowHeight="100"
            :headerHeight="90"
            rowSelection='multiple'
            :enableCellTextSelection="true"
            :ensureDomOrder="true"
            :pagination="true"
            :defaultColDef="defaultColDef"
            :suppressRowHoverHighlight="true"
            :suppressPaginationPanel="true"
            :suppressRowClickSelection="true"
            :unSortIcon="true"
            :alwaysMultiSort="true"
            :suppressRowTransform="true"
            style="width: 100%"
            domLayout="autoHeight"
            @grid-ready="onGridReady"
            @row-selected="onRowSelected"
            @first-data-rendered="onFirstDataRendered"
            @selection-changed="onSelectionChanged"
            :postSortRows="postSortRows"
        >
        </ag-grid-vue>
      </div>
      <div v-else class="table-preloader">
        <preloader/>
      </div>
      <div class="btn-tools">
        <div class="btn-box-item">
          <custom-checkbox
              v-if="appenedQuotas"
              name="automatic"
              :disabled="reportType === 'empty'"
              v-model="automaticGiving"
              label="Выдать отчет автоматически после прохождения"
          />
          <button v-if="appenedQuotas" class="btn btn-accent" :disabled="!isChecked" @click="quotaAppend">
            Выслать
            приглашение
          </button>
          <button v-else class="btn btn-accent" :disabled="!isChecked" @click="quotaRemove">забрать</button>
        </div>
        <div class="btn-box-item">
          <a :href="siteUrl" target="_blank" class="btn clear-btn">+ заказать новый продукт</a>
          <button class="popper-info btn btn-accent" @click="openModal">Заказать дополнительно</button>
        </div>
      </div>
      <pop-up-form title="Оформить заказ"
                   v-if="showModal"
                   @closeModal="showModal = false"/>
    </div>
  </div>


</template>

<script>
import {AgGridVue} from "ag-grid-vue3";
import {computed, onBeforeMount, reactive, ref, toRef, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import FreeQuotaAction from "../../components/Tables/CellRenderers/FreeQuotaAction.vue"
import {useRoute} from "vue-router";
import Preloader from "@/components/Technical/Preloader.vue";
import PopUpForm from "@/components/Popups/PopUpForm.vue";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";

export default {
  name: "quotas-modal",
  components: {CustomCheckbox, PopUpForm, AgGridVue, FreeQuotaAction, Preloader},
  props: {
    checkedList: {
      type: Array,
      required: true,
    },
    appenedQuotas: {
      type: Boolean,
      required: true
    },
    productOptions: {
      type: Array,
      required: true,
    }
  },
  setup(props, {emit}) {
    const store = useStore(),
        route = useRoute(),
        checkedList = toRef(props, "checkedList"),
        checkedProductList = ref([]),
        isChecked = ref(true),
        gridApi = ref(null),
        columnApi = ref(null),
        countOnPage = ref(10),
        siteUrl = process.env.VUE_APP_SITE;
    const reportType = route.params.product;
    const automaticGiving = ref(false);

    const rowData = computed(() => {
      return props.productOptions.map((quotas) => {
        return {
          id: quotas.id,
          name: quotas.name,
          countFree: quotas.countFree,
          countIssued: quotas.countIssued,
        }
      })
    })
    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    }

    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    const onFirstDataRendered = () => {
      gridApi.value.forEachNode(node => {
        if (route.params.product === node.data.id) {
          node.setSelected(true, true);
          let container = document.querySelector(".modal__table");
          container.querySelectorAll(`[row-index="${node.rowIndex}"]`)[1].scrollIntoView();
        }
      })
    }

    const postSortRows = params => {
      let rowNodes = params.nodes;
      if (rowNodes.length) {
        let nextInsertPos = 0;
        for (let i = 0; i < rowNodes.length; i++) {
          const selectRow = rowNodes[i].selected;
          if (selectRow === true) {
            rowNodes.splice(nextInsertPos, 0, rowNodes.splice(i, 1)[0]);
            nextInsertPos++;
          }
        }
      }
    };

    const onRowSelected = (e) => {
      const id = e.node.data.id;
      const isInclude = checkedProductList.value.includes(id);

      if (e.node.isSelected() && !isInclude) {
        checkedProductList.value.push(id);
        return;
      }
      if (!e.node.isSelected() && isInclude) {
        checkedProductList.value = checkedProductList.value.filter(
            (currentId) => currentId != id
        );
      }
    };

    const onSelectionChanged = () => {
      const selectedRows = gridApi.value.getSelectedRows();
      selectedRows.length ? isChecked.value = true : isChecked.value = false
    };


    const quotaAppend = () => {
      store
          .dispatch("users/quotaAppend", {
            products: checkedProductList.value,
            users: checkedList.value,
            allow_view_report: automaticGiving.value
          })
          .then(() => {
            store.dispatch("company/getUserQuotas")
            emit('closeModal')
          })
    }
    const quotaRemove = () => {
      store
          .dispatch("users/quotaRemove", {
            products: checkedProductList.value,
            users: checkedList.value
          })
          .then(() => {
            store.dispatch("company/getUserQuotas")
            emit('closeModal')
          })
    }

    const columnDefs = reactive({
      value: [
        {
          field: "id",
          hide: true,
        },
        {
          field: "check",
          headerName: "",
          editable: true,
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          minWidth: 50,
          maxWidth: 50,
          flex: 0,
        },
        {
          field: "name",
          headerName: "Продукты",
          wrapText: true,
          sortable: true,
          minWidth: 255,
          autoHeight: true,
          flex: 3,
        },
        {
          field: "countFree",
          headerName: "Доступно мест",
          wrapText: true,
          sortable: true,
          minWidth: 55,
          cellRenderer: "FreeQuotaAction",
          flex: 1,
        }
      ]
    })
    const morph = (int, array) => {
      return (array = array || ['человек', 'человека', 'человек']) && array[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]];
    }

    const showModal = ref(false)
    const openModal = () => {
      showModal.value = !showModal.value
    }

    watch(
        () => [route.params.id, route.params.product],
        () => {
          onFirstDataRendered()
        }
    )

    return {
      gridApi,
      columnApi,
      rowData,
      defaultColDef,
      columnDefs,
      onGridReady,
      countOnPage,
      siteUrl,
      onRowSelected,
      quotaAppend,
      quotaRemove,
      close,
      isChecked,
      onSelectionChanged,
      onFirstDataRendered,
      postSortRows,
      morph,
      showModal,
      openModal,
      reportType,
      automaticGiving
    }
  }
}

</script>
<style scoped lang="scss">

.modal-overlay {
  z-index: 5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  overflow: auto;
}

@media only screen and (max-height: 570px) {
  .modal {
    margin-top: 0 !important;
  }
}

.modal {
  display: block !important;
  overflow-y: initial !important;
  position: relative;
  background-color: white;
  height: 515px;
  width: 900px;
  margin-top: 10%;
  padding: 20px 40px;

  &__box {
    text-align: left;
  }

  &__title {
    font-weight: 400;
    font-size: 36px;
    line-height: 37px;
    @media (max-width: 715px) {
      font-size: 24px;
    }
  }

  &__text {
    padding-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    @media (max-width: 715px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__table {
    height: 300px;
    overflow-y: auto;
    margin: auto;
  }

  &__btn {
    padding-top: 30px;
  }
}

.close {
  position: absolute;
  right: 49px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.btn-tools {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  @media (max-width: 715px) {
    flex-direction: column;
  }
}

.btn-box-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 715px) {
  .modal {
    height: 650px;
  }
}


</style>